import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export default function MoreArticles({ slug, text }) {
    const StylePadding = styled.div`padding:10px;`

    return (
        <>
            <StylePadding>
                <center>
                    <a className="button_more" href={ slug } title={ text }>{ text }</a>
                </center>
            </StylePadding>
        </>
    )
}