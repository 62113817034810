import React from "react"
import styled from "styled-components"

export default function Layout6337({ mainTopLeft, mainTopRight, mainBottom, mainBottomSecondary, mainBottomThirdly, side, bottomFirstRow, bottom }) {
    const Style63 = styled.div`width: 63%;`
    const Style37 = styled.div`width: 37%;`
    const Style50 = styled.div`width: 50%;`

    return (
        <>
            <div className="wrap_news_block">
                <div className="container-fluid">
                    <div className="block-part">                    
                        <div className="block-part">
                            <div className="row row-glairy">
                                <Style63 className="col-glairy col-xs-12 col-md-8">
                                    <div className="row row-glairy">
                                        <Style50 className="col-glairy col-xs-12 col-md-6">
                                            <div className="block-s-part">
                                                <div className="row row-glairy">
                                                    <div className="col-glairy col-xs-12 col-md-12">
                                                        { mainTopLeft[0] }
                                                        { mainTopLeft[1] }
                                                        { mainTopLeft[2] }
                                                    </div>
                                                </div>
                                            </div>
                                        </Style50>
                                        <Style50 className="col-glairy col-xs-12 col-md-6">
                                            <div className="block-s-part">
                                                <div className="row row-glairy">
                                                    <div className="col-glairy col-xs-12 col-md-12">
                                                        { mainTopRight[0] }
                                                        { mainTopRight[1] }
                                                        { mainTopRight[2] }
                                                    </div>
                                                </div>
                                            </div>
                                        </Style50>
                                    </div>
                                    <div className="row row-glairy">
                                        <div className="col-glairy col-xs-12 col-md-12">
                                            { mainBottomSecondary }
                                        </div>
                                    </div>
                                    <div className="row row-glairy">
                                        <Style50 className="col-glairy col-xs-12 col-md-6">
                                            <div className="block-s-part">
                                                <div className="row row-glairy">
                                                    <div className="col-glairy col-xs-12 col-md-12">
                                                        { mainTopLeft[3] }
                                                        { mainTopLeft[4] }
                                                    </div>
                                                </div>
                                            </div>
                                        </Style50>
                                        <Style50 className="col-glairy col-xs-12 col-md-6">
                                            <div className="block-s-part">
                                                <div className="row row-glairy">
                                                    <div className="col-glairy col-xs-12 col-md-12">
                                                        { mainTopRight[3] }
                                                        { mainTopRight[4] }
                                                    </div>
                                                </div>
                                            </div>
                                        </Style50>
                                    </div>
                                    <div className="row row-glairy">
                                        <div className="col-glairy col-xs-12 col-md-12">
                                            { mainBottomThirdly }
                                        </div>
                                    </div>
                                    <div className="row row-glairy">                                       
                                        <div className="col-glairy col-xs-12 col-md-12">
                                            <div className="box box-middle">
                                                <div className="row row-glairy display-block">
                                                    <div className="box-content">
                                                        { mainBottom }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="row row-glairy">
                                        <div className="col-glairy col-xs-12 col-md-12">
                                            { bottomFirstRow }
                                        </div>
                                    </div>
                                </Style63>
                                <Style37 className="col-glairy col-xs-12 col-md-4">
                                    <div className="block-aside-part">
                                        <div className="row row-glairy">
                                            <div className="col-glairy col-xs-12 col-md-12">
                                                { side }
                                            </div>
                                        </div>
                                    </div>
                                </Style37>
                            </div>
                        </div>
                        <div className="row row-glairy">
                            <div className="col-glairy col-xs-12 col-md-12">
                                <div className="box box-middle">
                                    <div className="row row-glairy display-block">
                                        <div className="box-content">
                                            { bottom }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}