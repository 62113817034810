import React from "react"

export default function Home(props) {
    const category = props.category
    const color = {
        "Belföld":  1,
        "Külföld":  2,
        "Bulvár":   9,
        "Életmód":  10,
        "Gazdaság": 4,
        "Időjárás": 11,
        "Kultúra":  7,
        "Sport":    8,
        "TechTud":  5
    }
    const blockClass = "title_news_block title_news_block_" + color[category]

    return (
        <>
            <div className={blockClass}>
                <div className="container-fluid">
                    <span className="first">{ category }</span>
                </div>
            </div>
        </>
    )
}