import React from "react"
import Layout6337 from "../layouts/layout6337"
import Boxes from "./boxes"
import Ads from "../ads"
import BoxAd from "./boxAd"

const getCover = (article) => {
    if (article.cover === null || article.cover.childImageSharp === null || article.cover.childImageSharp.fluid === null) {
        return null
    } else {
        return article.cover.childImageSharp.fluid
    }
}

export default function CategoryTopNews({ articles, category }) {
    const styles = {
        0:  1, 1:  2, 2:  2, 3:  3, 4:  3,
        5:  2, 6:  2, 7:  1, 8:  3, 9:  3,
        10: 16, 11: 16, 12: 16,
        13: 17, 14: 17, 15: 17, 16: 17, 17: 17,
        18: 12, 19: 12, 20: 14, 21: 14, 22: 14,
        23: 12, 24: 12, 25: 14, 26: 14, 27: 14,
        28: 14, 29: 14, 30: 14, 31: 14
    }

    const news = articles.map((article, idx) => <Boxes box={styles[idx]} title={article.title} excerpt={article.excerpt} category={article.category.name} cover={getCover(article)} />)

    const ads2 = <Ads adNo={331} isBlock={false} />
    const ads3 = <Ads adNo={333} isBlock={false} />
    
    const mainTopLeft = [news[0], news[1], news[2], news[3], news[4]]
    const mainTopRight = [news[5], news[6], news[7], news[8], news[9]]
    const mainBottom = [news[10], news[11], news[12]]
    const bottom = [news[13], news[14], news[15], news[16], news[17]]
    const side = [news[18], news[19], <><script mode="block" data-zone="mp_adb_336280_zone1" src="https://head-clickfusion.com/client-v2.js"></script></>, <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1213102.html" width="350" height="650" frameborder="0" scrolling="no"></iframe> } />, news[20], news[21], news[22], <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/netex323.html" width="350" height="300" frameborder="0" scrolling="no"></iframe> } />, news[23], news[24], <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/netex273.html" width="350" height="320" frameborder="0" scrolling="no"></iframe> } />]

    const mainBottomSecondary = <BoxAd isBlock={false} ad={ <div data-gz-block="17f029ff-a000-419d-88fd-926b9e8b1133"></div> } />
    const mainBottomThirdly = <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1213092.html" width="700" height="600" frameborder="0" scrolling="no"></iframe> } />

    return (
        <>
            <Layout6337
                mainTopLeft={mainTopLeft}
                mainTopRight={mainTopRight}
                mainBottom={mainBottom}
                mainBottomSecondary={mainBottomSecondary}
                mainBottomThirdly={mainBottomThirdly}
                side={side}
                bottomFirstRow={<><script mode="block" data-zone="mp_adb_336280_zone2" src="https://head-clickfusion.com/client-v2.js"></script></>}
                bottom={bottom}
            />
			<iframe src="https://mindenpercben.hu/r/netex272.html" width="700" height="120" frameborder="0" scrolling="no"></iframe>
            <script mode="block" data-zone="mp_adb_336280_zone3" src="https://head-clickfusion.com/client-v2.js"></script>
        </>
    )
}